<template>
  <div class="container py-5">
    <p class="fw-bold">第十二屆全國雷射美容與臉部年輕化學術大會</p>
    <p>時間：2024/04/12-14</p>
    <p>地點：上海富悅大酒店</p>
    <p>展位：T10-1</p>

    <p>
    誠摯邀請
    <br>
    各位醫美同儕專家醫師共赴學術與科技交流的國際醫學高峰會，一起前瞻全球醫美新技術新產品新科技的趨勢與重要機會。
    <br>
    展位參觀T10-1
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_38.jpg"
    />


  </div>
</template>
<script>
export default {};
</script>
